import { getPrivateProfile } from 'lib/db';
import { getPath } from 'lib/router';
import { relativeUrl } from 'lib/utils/url';
import type { UserDoc, WithId } from 'schemas/types';

export const requiresOnboarding = (privateUser: WithId<UserDoc>) => {
  return !privateUser.onboarded;
};

// TODO
// export const requiresInfo = (nextState: 'book' | 'host', fromPath: string) => {
//   const requiredFieldKeys = [];

//   return {
//     requiresAny: requiredFieldKeys.length > 0,
//     requiredFieldKeys,
//     redirectPath: getPath('onboard', {
//       from: fromPath,
//       required_fields: requiredFieldKeys.join(','),
//     }),
//   };
// };

const NO_ONBOARDING_PATHS = [getPath('santa.register')];

export const getPostLoginRedirectPath = async (
  from?: string | null,
  privateUser?: WithId<UserDoc> | null,
  userId?: string,
) => {
  if (!privateUser && userId) privateUser = await getPrivateProfile(userId);

  if (from) from = relativeUrl(from, true);

  if (from && NO_ONBOARDING_PATHS.includes(from.replace(/\?.*/, ''))) return from;

  if (privateUser && requiresOnboarding(privateUser)) return getPath('onboard', { from });

  if (from) return from;

  if (privateUser?.isLister) return getPath('host');

  return getPath('trips');
};
